import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import IntegrationDetail from '../components/IntegrationsBlock/IntegrationDetail';
import GeneralHead from '../components/generalHead';

const Integration = ({ data: { integration } }) => (
  <Layout forceShowHeader forceShowFooter>
    <IntegrationDetail integrationContent={integration} />
  </Layout>
);

export const Head = ({
  data: {
    integration: { seo, headHtml, indexable, schemaMarkup },
  },
}) => (
  <GeneralHead>
    <SEO
      seo={seo}
      headHtml={headHtml}
      indexable={indexable}
      schemaMarkup={schemaMarkup}
    />
  </GeneralHead>
);

export const query = graphql`
  query($id: String!) {
    integration: datoCmsIntegrationPage(id: { eq: $id }) {
      id
      internalName
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      indexable
      headHtml
      slug
      schemaMarkup {
        id
        schema
      }
      title
      subtitle
      caption
      excerpt
      integrationImage {
        image {
          gatsbyImageData(
            imgixParams: {
              fm: "webp"
              auto: "compress"
              maxW: 1080
              fit: "clip"
              q: 35
            }
          )
          url
          title
        }
      }
      body {
        value
        blocks
        links {
          ...datoCmsCallToAction
        }
      }
      content {
        id
        content
        originalId
      }
      category {
        name
        id
        slug
      }
    }
  }
`;

export default Integration;
